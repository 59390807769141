<template>
     <div class="ks-page-header">
        <section class="ks-title">
            <h3>{{$route.meta.head}}</h3>
            <button class="btn btn-outline-primary ks-light ks-mail-navigation-block-toggle" data-block-toggle=".ks-mail > .ks-navigation">Menu</button>
        </section>
    </div>
    <div class="ks-page-content">
        <div class="ks-page-content-body ks-mail">
            <div class="ks-navigation ks-browse ks-scrollable" data-auto-height>
                <div class="ks-wrapper">
                    <div class="ks-separator">
                        Menu
                    </div>
                    <ul class="ks-menu">
                        <li>
                            <router-link to="/quotation" class="ks-menu-item" :class="{'ks-active': $route.name == 'quotation approval'}" title="ERM Partners not imported/imported to PMP">
                                <span class="ks-text">Quotation Approval</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>

            <router-view v-slot="{ Component }" name="main">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
        </div>
    </div>
</template>

<script>

export default {
   
}
</script>

<style scoped>
@import url(/assets/styles/apps/mail.min.css);
</style>